import Head from './pages/header'
import Bottom from './pages/bottom'
import Valo from './val/val'
import Text from './val/text'
import Map from './pages/map'
import Divider from './pages/divider'
import "./index.css"

function App() {
  return (
    <>
    <Head></Head>
    <Valo></Valo>
    <Text></Text>
    <Divider></Divider>
    <Map></Map>
    <Bottom></Bottom>
    </>
  );
}

export default App;
