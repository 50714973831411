/* eslint-disable jsx-a11y/alt-text */
import "./head.css"
import Star from "../pages/star.gif"

function Head() {
  return (
    <>
    <div className="upperBar">
    <a href="/" className="links2">
      kxvin.le
    </a>
    <a href="https://74ff5f68.test-2yn.pages.dev/" className="links2">
      portfolio
    </a>
       </div>
       <div className="image-container">
<img src = {Star} className="star"></img>
    </div>
    </>
  );
}
/*
<div className="image-container">
<img src = {Star} className="star"></img>
    </div>
*/
export default Head;
